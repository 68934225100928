<template>
  <div class="outerContainer">
    <div class="topNav">
      <div class="logoContainer">
        <router-link to="/">SVENJA RAETZSCH</router-link>
        <div class="button-container">
          <input type="checkbox" id="checkbox3" class="checkbox3 visuallyHidden">
          <label for="checkbox3">
            <div @click="toggleMenu" class="hamburger" :class="{ open: isOpen }">
              <span class="bar bar1"></span>
              <span class="bar bar2"></span>
            </div>
          </label>
        </div>
      </div>
      <transition name="menu-slide">
        <div v-if="isOpen" class="outerNavContainer">
          <div class="navigation-container" @click="navigate('/')">
            <router-link to="/">START</router-link>
          </div>
          <div class="navigation-container" @click="navigate('/projects')">
            <router-link to="/projects">PROJECTS</router-link>
          </div>
          <div class="navigation-container" @click="navigate('/skills')">
            <router-link to="/skills">SKILLS</router-link>
          </div>
        </div>
      </transition>
    </div>
    <div class="mainNav">
      <Breadcrumbs></Breadcrumbs>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/breadcrumbs/BreadCrumbs";
export default {
  name: 'MainNav',
  components: {
    Breadcrumbs
  },
  data() {
    return {
      isOpen: false,
      routeChanged: false,
    };
  },
  watch: {
    // Watch for route changes and set the flag
    $route(to, from) {
      this.routeChanged = to.path !== from.path;
    },
  },
  methods: {
    // Toggle the menu
    toggleMenu() {
      this.isOpen = !this.isOpen;
      // If the route has changed, reset the flag
      if (this.routeChanged) {
        this.routeChanged = false;
      }
    },
    // Navigate to a view by providing the path
    navigate(extension) {
      this.$router.push({
        path: extension,
      });
      // Close the menu after navigation
      this.isOpen = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    // Set the flag to indicate that the route is changing
    this.routeChanged = true;
    next();
  },
  beforeRouteEnter(to, from, next) {
    // Ensure the component is fully loaded before opening the menu
    next(vm => {
      vm.routeChanged = false;
    });
  },
}
</script>

<style scoped>

  .outerContainer{
    z-index: 100;
  }

  .topNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
  }

  .iconBar{
    display: flex;
    flex-direction: row;
    margin: 16px;
    gap: 16px;
  }

  .iconBar a:hover svg{
    transform: scale(1.5);
    fill: white;
  }

  .logoContainer{
    letter-spacing: 1.5px;
    margin: 16px 24px 16px 70px;
    font-size: 24px;
    font-weight: 500;
  }

  svg{
    width: 24px;
    height: 24px;
    fill: darkgray;
    transition: all 0.3s;
  }

  a.router-link-exact-active{
    color: white;
    font-weight: 500;
  }


  .outerNavContainer{
  padding: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 10000000000000000000000000;
  background-color: blue;
}

.mobile-header{
  background-color: var(--darkgrey);
  height: 50px;
  position:fixed;
  width:100%;
  z-index: 1000;
}

.logo-container {
  font-size: 14px;
  margin: 16px 24px 15px 24px;
  font-weight: 500;
  font-family: var(--secondaryFont);
  display: flex;
  justify-content: right;
  color: white;
}

.navigation-container{
  padding: 24px;
  border-bottom: 1px solid black;
}

.navigation-container:hover a{
  font-size: 30px;
}


a{
  font-size: 24px;
  transition: all 330ms ease-in-out;
  color: darkgray;
  line-height: 40px;
}

.navigation-container:hover{
  cursor:pointer;
}

/* GENERAL STYLES */
.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}
.hamburger {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 25px;
  left: 24px;
  cursor: pointer;
}

.hamburger .bar {
  width: 30px;
  height: 3px;
  background-color: white;
  display: block;
  position: absolute;
  transition: all 0.4s ease-in-out;
}

/* Die Position der beiden Bars */
.bar1 {
  top: 0;
}

.bar2 {
  top: 13px;
}

/* Animation bei Klick, wenn das Menü geöffnet wird */
.hamburger.open .bar1 {
  transform: rotate(45deg);
  top: 7px; /* Gleiche Position wie bar2 */
}

.hamburger.open .bar2 {
  transform: rotate(-45deg);
  top: 7px; /* Beide Bars sind auf der gleichen Höhe */
}

</style>
